import { Tree } from 'antd';
import { DataNode, EventDataNode } from 'antd/es/tree';
import React, { FC, useCallback, useMemo } from 'react';
import CheckListIcon from '../../../assets/svg/icons/checkList';
import ChevronDown from '../../../assets/svg/icons/chevronDown';
import { IAccessPoint, ITreeNode, TreeNodeType } from '../../../typings/treeNode';
import { renderTreeDataNodes, setDataNodePropsToTreeNodesWithIcon, TRenderNodeFunction } from '../../../utils/tree';
import Button from '../button';
import { ButtonSize, ButtonType } from '../button/types';
import Checkbox from '../checkbox';
import ErrorPlaceholder from '../errorPlaceholder';
import { IEquipmentTree } from './types';

const EquipmentTree: FC<IEquipmentTree> = (props) => {
  const {
    isEditable = false,
    data = [],
    onEdit = () => {},
    onClickErrorButton = () => {},
    permissionObject = {},
    nodeTitle = 'Доступно абоненту',
  } = props;

  const mappedData = useMemo(() => setDataNodePropsToTreeNodesWithIcon(data, { key: '' }, { key: '' }), [data]);

  const renderAccessPoint: TRenderNodeFunction<IAccessPoint> = useCallback(
    (node: IAccessPoint, key = '') => (
      <div className="equipment-tree__node-container">
        <span className="equipment-tree__node-title">{node.name}</span>
        <div className="equipment-tree__node-tools-wrapper">
          <span className="equipment-tree__node-tool-name">{nodeTitle}</span>
          <Checkbox
            disabled={!isEditable || (node.isEdit === undefined ? false : !node.isEdit)}
            checked={node.isSelected}
            onChange={(checked) => {
              onEdit(key, { ...node, isSelected: checked });
            }}
          />
        </div>
      </div>
    ),
    [isEditable, nodeTitle, onEdit]
  );

  const renderTreeNode: TRenderNodeFunction<ITreeNode> = (node: ITreeNode) => (
    <div className="equipment-tree__node-container equipment-tree__node-expand-title">{node.name}</div>
  );

  const treeData = useMemo<DataNode[]>(
    () => renderTreeDataNodes(mappedData, renderAccessPoint, renderTreeNode),
    [mappedData, renderAccessPoint]
  );

  const highlightAccessPoint = useCallback(
    (treeNode: EventDataNode<DataNode>) => treeNode.key.toString().includes(TreeNodeType.accessPoint),
    []
  );

  return (
    <div className="equipment-tree">
      {treeData && treeData.length > 0 ? (
        <Tree
          defaultExpandAll
          rootClassName="equipment-tree__root"
          treeData={treeData}
          switcherIcon={<ChevronDown />}
          selectable={false}
          showIcon
          filterTreeNode={highlightAccessPoint}
          motion={null}
        />
      ) : (
        <ErrorPlaceholder
          containerClassName="equipment-tree__error"
          icon={<CheckListIcon />}
          iconContainerClassName="equipment-tree__error-icon"
          text="Для настройки доступа абонента к оборудованию, сначала необходимо расставить группы доступа в разделе «Объекты»"
          textContainerClassName="equipment-tree__error-text"
        >
          {permissionObject.edit && (
            <div className="equipment-tree__error-content">
              <Button size={ButtonSize.small} type={ButtonType.outline} onClick={onClickErrorButton}>
                Группы доступа
              </Button>
            </div>
          )}
        </ErrorPlaceholder>
      )}
    </div>
  );
};

export default EquipmentTree;
