import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { sectionFlatsInitApiSettings } from '../../../../api/flats/config';
import {
  flatCountRemainingUrl,
  getObjectBuildingAllFlatsUrl,
  getObjectBuildingAllFloorsUrl,
  getObjectBuildingSectionAllFlatsUrl,
  securityDeckFlatListAllUrl,
  securityDeckFlatListUrl,
} from '../../../../constants/api';
import { selectAllOptionKey } from '../../../../constants/select';
import { useApi } from '../../../../hooks/useApi';
import { IApiResponse, IApiSettings, IApiSortField } from '../../../../typings/api';
import { IFlat, IFlatDeleteApiBody, ISecurityDeck } from '../../../../typings/systems/flat';
import SectionFlatsTools from '../../../ui/sectionFlatsTools';
import { ISelectOption } from '../../../ui/select/types';
import AddFlatsModal from '../addFlatsModal';
import DeleteFlatModal from '../deleteFlatModal';
import SectionFlatTable from '../sectionFlatTable';
import { ISectionFlatTableSelection } from '../sectionFlatTable/types';
import { ISectionFlats } from './types';
import { EFlatsStatus } from '../types';
import { getRequest, postRequest } from '../../../../api';
import { ButtonType } from '../../../ui/button/types';
import { ESystemTabsIds } from '../../../../pages/systems/item/types';
import SecurityDecksTable from '../securityDecksTable';
import SkinIndexModal from '../skinIndexModal';

const SectionFlats: FC<ISectionFlats> = (props) => {
  const {
    systemId = '',
    buildingId = '',
    sections = [],
    updateBuildings = () => {},
    updateAvailableTabs = () => {},
    permissions = {},
    isActive = true,
    onChangeFlatApiSettings = () => {},
    flatsStatus = EFlatsStatus.default,
    onChangeFlatsStatus = () => {},
    isFiveThousandth,
    tabId,
  } = props;

  const { data: flats, sendRequest, loading: flatsLoading } = useApi<IApiResponse<IFlat>>(postRequest);

  const { data: floors, sendRequest: getFloors, loading: floorsLoading } = useApi<number[]>(getRequest);

  const { data: flatsCounter, sendRequest: getFlatsCounter } = useApi<number>(getRequest);

  const {
    data: securityDecks,
    sendRequest: getSecurityDecks,
    loading: securityDecksLoading,
  } = useApi<IApiResponse<ISecurityDeck>>(postRequest);

  const [selectedSectionId, setSelectedSectionId] = useState<string | number>(selectAllOptionKey);
  const [selectedFloorId, setSelectedFloorId] = useState<string | number>(selectAllOptionKey);
  const [apiSettings, setApiSettings] = useState<IApiSettings<IFlat>>(sectionFlatsInitApiSettings);
  const [searchValue, setSearchValue] = useState<string>('');

  const [addFlatsModalIsOpen, setAddFlatsModalIsOpen] = useState<boolean>(false);
  const [deleteFlatModalIsOpen, setDeleteFlatModalIsOpen] = useState<boolean>(false);
  const [skinIndexModalIsOpen, setSkinIndexModalIsOpen] = useState<boolean>(false);
  const [deleteFlatId, setDeleteFlatId] = useState<string>('');

  const [flatDeleteApiBody, setFlatDeleteApiBody] = useState<IFlatDeleteApiBody>({});
  const [tableSelection, setTableSelection] = useState<ISectionFlatTableSelection>({});

  useEffect(() => {
    getFlatsCounter(flatCountRemainingUrl());
  }, []);

  useEffect(() => {
    if (buildingId) {
      if (tabId === ESystemTabsIds.distributionFlats) {
        sendRequest(getObjectBuildingAllFlatsUrl(buildingId), sectionFlatsInitApiSettings);
        getFloors(getObjectBuildingAllFloorsUrl(buildingId));
      } else {
        getSecurityDecks(securityDeckFlatListAllUrl(buildingId), sectionFlatsInitApiSettings);
      }
      setSelectedSectionId(selectAllOptionKey);
      setSelectedFloorId(selectAllOptionKey);
    }
  }, [buildingId]);

  const updateDeleteFlatApiSettings = useCallback(
    (
      updatedSectionId: string,
      updatedApiBodySettings: IFlatDeleteApiBody,
      updatedTableSelection?: ISectionFlatTableSelection
    ) => {
      onChangeFlatApiSettings({
        buildingId: buildingId,
        sectionId: updatedSectionId === selectAllOptionKey ? '' : updatedSectionId,
        body: updatedApiBodySettings,
        total: flats?.totalCount,
      });
      setFlatDeleteApiBody(updatedApiBodySettings);
      if (updatedTableSelection) {
        setTableSelection(updatedTableSelection);
      }
    },
    [buildingId, flats?.totalCount, onChangeFlatApiSettings]
  );

  useEffect(() => {
    if (isActive) {
      updateDeleteFlatApiSettings(selectedSectionId?.toString(), flatDeleteApiBody);
    }
  }, [isActive, flats]);

  const requestData = useCallback(
    async (
      reqSettings: IApiSettings<IFlat>,
      buildId: string,
      sectionId = selectAllOptionKey,
      floorId = selectedFloorId
    ) => {
      if (buildId) {
        const params = floorId && floorId !== selectAllOptionKey ? { params: { floor: floorId } } : {};
        if (tabId === ESystemTabsIds.distributionFlats) {
          if (sectionId === selectAllOptionKey) {
            await sendRequest(getObjectBuildingAllFlatsUrl(buildId), reqSettings, params);
          } else {
            await sendRequest(getObjectBuildingSectionAllFlatsUrl(buildId, sectionId), reqSettings, params);
          }
          getFloors(getObjectBuildingAllFloorsUrl(buildingId));
        } else if (tabId === ESystemTabsIds.securityDeck) {
          if (sectionId === selectAllOptionKey) {
            await getSecurityDecks(securityDeckFlatListAllUrl(buildId), reqSettings, params);
          } else {
            await getSecurityDecks(securityDeckFlatListUrl(buildId, sectionId), reqSettings, params);
          }
        }
      }
      updateAvailableTabs();
    },
    [buildingId, getFloors, getSecurityDecks, selectedFloorId, sendRequest, tabId, updateAvailableTabs]
  );

  const resetFlats = useCallback(() => {
    const newApiSettings: IApiSettings<IFlat> = { ...apiSettings };

    if (isActive && flatsStatus) {
      updateDeleteFlatApiSettings(selectedSectionId.toString(), {}, {});
      onChangeFlatsStatus(EFlatsStatus.default);
      newApiSettings.page = newApiSettings.page > 0 ? newApiSettings.page - 1 : newApiSettings.page;
      setApiSettings(newApiSettings);
    }
  }, []);

  const reloadFlats = useCallback(async () => {
    const newApiSettings: IApiSettings<IFlat> = { ...apiSettings };

    if (isActive && flatsStatus) {
      updateDeleteFlatApiSettings(selectedSectionId.toString(), {}, {});
      onChangeFlatsStatus(EFlatsStatus.default);
      newApiSettings.page = newApiSettings.page > 0 ? newApiSettings.page - 1 : newApiSettings.page;
      setApiSettings(newApiSettings);
    }
    await requestData(newApiSettings, buildingId, selectedSectionId.toString());
  }, [
    apiSettings,
    buildingId,
    flatsStatus,
    isActive,
    onChangeFlatsStatus,
    requestData,
    selectedSectionId,
    updateDeleteFlatApiSettings,
  ]);

  useEffect(() => {
    if (isActive && flatsStatus === EFlatsStatus.needUpdate) {
      reloadFlats();
    } else if (isActive && flatsStatus === EFlatsStatus.needReset) {
      resetFlats();
    }
  }, [flatsStatus]);

  const handleOnChangeSection = useCallback(
    async (sectionId: string | number) => {
      setSelectedSectionId(sectionId);
      setSearchValue('');
      const newApiSettings: IApiSettings<IFlat> = {
        ...sectionFlatsInitApiSettings,
        sortFields: apiSettings.sortFields,
      };
      await requestData(newApiSettings, buildingId, sectionId.toString());
      setApiSettings(newApiSettings);

      updateDeleteFlatApiSettings(sectionId?.toString(), {}, {});
    },
    [apiSettings.sortFields, buildingId, requestData, updateDeleteFlatApiSettings]
  );

  const handleOnChangeFloor = useCallback(
    async (floorId: string | number) => {
      setSelectedFloorId(floorId);
      setSearchValue('');
      await requestData(apiSettings, buildingId, selectedSectionId?.toString(), floorId.toString());
    },
    [apiSettings, buildingId, requestData, selectedSectionId]
  );

  const handleOnSearch = useCallback(
    async (value: string) => {
      setSearchValue(value);
      const newApiSettings: IApiSettings<IFlat> = { ...apiSettings, page: 0, search: value };
      await requestData(newApiSettings, buildingId, selectedSectionId.toString());
      setApiSettings(newApiSettings);
      updateDeleteFlatApiSettings(selectedSectionId?.toString(), { search: value }, {});
    },
    [apiSettings, buildingId, requestData, selectedSectionId, updateDeleteFlatApiSettings]
  );

  const handleOnClickButton = useCallback(() => {
    setAddFlatsModalIsOpen(true);
  }, []);

  const handleOnDeleteFlat = useCallback((flatId: string) => {
    setDeleteFlatId(flatId);
    setDeleteFlatModalIsOpen(true);
  }, []);

  const handleOnOkModal = useCallback(
    async (setter: React.Dispatch<React.SetStateAction<boolean>>, updatedApiSettings = apiSettings) => {
      setter(false);
      updateBuildings();
      await requestData(updatedApiSettings, buildingId, selectedSectionId.toString());
      getFlatsCounter(flatCountRemainingUrl());
    },
    [apiSettings, buildingId, getFlatsCounter, requestData, selectedSectionId, updateBuildings]
  );

  const handleOnOkDeleteModal = useCallback(async () => {
    const updatedApiSettings = { ...apiSettings };
    if ((flats?.items?.length || 0) < 2) {
      updatedApiSettings.page = updatedApiSettings.page > 0 ? updatedApiSettings.page - 1 : updatedApiSettings.page;
    }
    await handleOnOkModal(setDeleteFlatModalIsOpen, updatedApiSettings);
  }, [apiSettings, flats?.items?.length, handleOnOkModal]);

  const handleOnCancelDeleteFlatModal = useCallback(() => {
    setDeleteFlatModalIsOpen(false);
    setDeleteFlatId('');
  }, []);

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings: IApiSettings<IFlat> = { ...apiSettings, page: page - 1 };
      await requestData(newApiSettings, buildingId, selectedSectionId.toString());
      setApiSettings(newApiSettings);
    },
    [apiSettings, buildingId, requestData, selectedSectionId]
  );

  const handleOnSort = useCallback(
    async (sortResults: IApiSortField<any>[]) => {
      const newApiSettings: IApiSettings<any> = { ...apiSettings, sortFields: sortResults };
      await requestData(newApiSettings, buildingId, selectedSectionId.toString());
      setApiSettings(newApiSettings);
    },
    [apiSettings, buildingId, requestData, selectedSectionId]
  );

  const handleOnChangeTableSelection = useCallback(
    (updatedTableSelection: ISectionFlatTableSelection) => {
      const updatedFlatDeleteApiBody = {
        ...flatDeleteApiBody,
        selectAll: Boolean(updatedTableSelection.selectAll),
        selectedIds: updatedTableSelection.selectAll
          ? updatedTableSelection.excludedIds || []
          : updatedTableSelection.selectedIds || [],
      };
      updateDeleteFlatApiSettings(selectedSectionId?.toString(), updatedFlatDeleteApiBody, updatedTableSelection);
    },
    [flatDeleteApiBody, selectedSectionId, updateDeleteFlatApiSettings]
  );

  const floorsData = useMemo(
    () =>
      floors
        ? floors.map<ISelectOption>((val) => ({
            value: val,
            title: val.toString(),
          }))
        : [],
    [floors]
  );

  return (
    <div className="section-flats">
      <AddFlatsModal
        flatsCounter={flatsCounter}
        isOpen={addFlatsModalIsOpen}
        onOk={() => handleOnOkModal(setAddFlatsModalIsOpen)}
        onCancel={() => setAddFlatsModalIsOpen(false)}
        sections={sections}
        systemId={systemId}
        buildingId={buildingId}
        isFiveThousandth={isFiveThousandth}
      />
      <SkinIndexModal
        objectId={systemId}
        isOpen={skinIndexModalIsOpen}
        onOk={() => handleOnOkModal(setSkinIndexModalIsOpen)}
        sections={sections}
        onCancel={() => setSkinIndexModalIsOpen(false)}
      />
      <DeleteFlatModal
        isOpen={deleteFlatModalIsOpen}
        onOk={handleOnOkDeleteModal}
        onCancel={handleOnCancelDeleteFlatModal}
        flatId={deleteFlatId}
      />
      <SectionFlatsTools
        selects={[
          {
            title: 'Подъезд',
            onChange: handleOnChangeSection,
            value: selectedSectionId,
            options: [
              { value: selectAllOptionKey, data: 'Все', title: 'Все' },
              ...sections.map<ISelectOption>((section) => ({
                value: section.id,
                title: section.name,
              })),
            ],
          },
          {
            title: 'Этаж',
            onChange: handleOnChangeFloor,
            value: selectedFloorId,
            containerClassName: tabId === ESystemTabsIds.securityDeck ? 'section-flats__hide' : '',
            options: [{ value: selectAllOptionKey, data: 'Все', title: 'Все' }, ...floorsData],
          },
        ]}
        inputsSearch={[
          {
            placeholder: 'Поиск по квартире',
            value: searchValue,
            onSearch: handleOnSearch,
          },
        ]}
        buttons={
          permissions.edit && tabId === ESystemTabsIds.distributionFlats
            ? [
                ...(isFiveThousandth
                  ? [
                      {
                        type: ButtonType.outline,
                        onClick: () => setSkinIndexModalIsOpen(true),
                        children: 'Назначить клеммы',
                      },
                    ]
                  : []),
                {
                  type: ButtonType.outline,
                  onClick: handleOnClickButton,
                  children: 'Добавить квартиры',
                },
              ]
            : []
        }
      />
      <div className="section-flats__content-wrapper">
        {(flatsCounter || flatsCounter === 0) && (
          <div className="section-flats__info">Доступно квартир {flatsCounter}</div>
        )}
        {tabId === ESystemTabsIds.distributionFlats ? (
          <SectionFlatTable
            isFiveThousandth={isFiveThousandth}
            handleOnOkModal={handleOnOkModal}
            sections={sections}
            systemId={systemId}
            flats={flats?.items || []}
            loading={flatsLoading || floorsLoading}
            pageSize={flats?.pageSize}
            total={flats?.totalCount}
            onDeleteFlat={handleOnDeleteFlat}
            currentPage={flats?.page}
            onChangePage={handleOnChangeTablePage}
            buildingId={buildingId}
            onEditFlat={reloadFlats}
            onSort={handleOnSort}
            sortOrders={apiSettings.sortFields}
            isSearch={!!searchValue}
            permissions={permissions}
            tableSelection={tableSelection}
            onChangeTableSelection={handleOnChangeTableSelection}
          />
        ) : tabId === ESystemTabsIds.securityDeck ? (
          <SecurityDecksTable
            isFiveThousandth={isFiveThousandth}
            handleOnOkModal={handleOnOkModal}
            sections={sections}
            systemId={systemId}
            flats={securityDecks?.items || []}
            loading={securityDecksLoading}
            pageSize={securityDecks?.pageSize}
            total={securityDecks?.totalCount}
            currentPage={securityDecks?.page}
            onChangePage={handleOnChangeTablePage}
            buildingId={buildingId}
            onEditFlat={reloadFlats}
            onSort={handleOnSort}
            sortOrders={apiSettings.sortFields}
            isSearch={!!searchValue}
            permissions={permissions}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SectionFlats;
